import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import "./App.css";
import abi from "./utils/WavePortal.json";

const App = () => {

  let mensaje = React.createRef();

  /*
  * Just a state variable we use to store our user's public wallet.
  */
  const [currentAccount, setCurrentAccount] = useState("");

  /**
  * Create a variable here that holds the contract address after you deploy!
  */
  const [allWaves, setAllWaves] = useState([]);

  const contractAddress = "0xB0B1c9cC38dbD1A455d2F038ccEbeaF11E5Adf0E";
  //"0xAF91063741297abec9311975187c244617234182";
  //"0xa96D1b23D4787624f90706636A34543A172E2B6C";

  /**
   * Create a variable here that references the abi content!
   */
  const contractABI = abi.abi;


  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        console.log("Make sure you have metamask!");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);
      }

      /*
      * Check if we're authorized to access the user's wallet
      */
      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log("Found an authorized account:", account);
        setCurrentAccount(account)
        
        getAllWaves()

      } else {
        console.log("No authorized account found")
      }
    } catch (error) {
      console.log(error);
    }
  }


  /**
  * Implement your connectWallet method here
  */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);

      getAllWaves();

      
    } catch (error) {
      console.log(error)
    }
  }


  const wave = async () => {
    const waveText = mensaje.current.value;

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);

        let count = await wavePortalContract.getTotalWaves();
        console.log("Retrieved total wave count...", count.toNumber());

        /*
        * Execute the actual wave from your smart contract
        */
        const waveTxn = await wavePortalContract.wave(waveText,  { gasLimit: 300000 });
        console.log("Mining...", waveTxn.hash);

        await waveTxn.wait();
        console.log("Mined -- ", waveTxn.hash);

        count = await wavePortalContract.getTotalWaves();
        console.log("Retrieved total wave count...", count.toNumber());


        getAllWaves();


      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }


  /*
   * Create a method that gets all waves from your contract
   */
  const getAllWaves = async () => {
    const { ethereum } = window;

    try {
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);
        const waves = await wavePortalContract.getAllWaves();


        /*
         * We only need address, timestamp, and message in our UI so let's
         * pick those out
         */
        let wavesCleaned = [];

        waves.forEach(wave => {
          console.log("wave data:" +JSON.stringify(wave));
          wavesCleaned.push({
            address: wave.waver,
            timestamp: new Date(wave.timestamp * 1000),
            message: wave.message
          });
        });


        /*
         * Store our data in React State
         */
        setAllWaves(wavesCleaned.reverse());
      } else {
        console.log("Ethereum object doesn't exist!")
      }
    } catch (error) {
      console.log(error);
    }
  }



  // useEffect(() => {
  //   checkIfWalletIsConnected();
  // }, [])
  /**
 * Listen in for emitter events!
 */
useEffect(() => {
  checkIfWalletIsConnected();

  let wavePortalContract;

  const onNewWave = (from, timestamp, message) => {
    console.log("NewWave", from, timestamp, message);
    setAllWaves(prevState => [
      ...prevState,
      {
        address: from,
        timestamp: new Date(timestamp * 1000),
        message: message,
      },
    ]);
  };

  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);
    wavePortalContract.on("NewWave", onNewWave);
  }

  //webhook
  return () => {
    if (wavePortalContract) {
      wavePortalContract.off("NewWave", onNewWave);
    }
  };
}, []);





  return (
    <div className="mainContainer">
      <div className="dataContainer">
        <div className="header">
         ¿Cual es mejor jugador de futbol de la historia mundial?⚽
        </div>

        <div className="bio">
          Uno de cada dos acertantes puede ganar 0.0001ETH
        </div>

        {currentAccount && (

          <div>
            <div className="todoCentrado">
              <textarea ref={mensaje} className="mensaje" style={{ width: "100%", padding:10}} name="mensaje" rows="4" placeholder="Escribe el nombre del jugador...">
              </textarea>
            </div>
            <div className="todoCentrado">
              <button className="waveButton" onClick={wave}>
                ok
              </button>
            </div>
          </div>
        )}

        {/*
        * If there is no currentAccount render this button
        */}
        {!currentAccount && (
          <button className="waveButton" onClick={connectWallet}>
            Conectar Wallet
          </button>
        )}


        {allWaves.map((wave, index) => {
          return (
            <div key={index} style={{ backgroundColor: "OldLace", marginTop: "16px", padding: "8px" }}>
              <div className="direccion">Address: {wave.address}</div>
              <div className="fecha">Fecha: {wave.timestamp.toString()}</div>
              <div className="mensajes">{wave.message}</div>
            </div>)
        })}


      </div>
    </div>
    );
  }
export default App